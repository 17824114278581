import React, { createContext, FC, useContext, useEffect, useMemo, useState } from 'react'
import { notifyError } from '../components/notification/Notification'
import { Buffer } from 'buffer'
import { accessToken } from '../lib/microsoftToken'
import { RoleRights, Roles, RoleState } from './roles'
import { ProductionSite } from '../features/order/order'

interface RoleProviderProps {
  children: React.ReactNode
}

interface RoleContextType {
  roles: RoleState | null
  getSitesWithEditRightsOfType: (businessUnit: string) => string[]
  sites: ProductionSite[]
  selectedSite: ProductionSite | undefined
  setSelectedSite: (lab: ProductionSite) => void
}

const RoleContext = createContext<RoleContextType | undefined>(undefined)
const KEY = 'selectedSite'

const getRolesFromAccessToken = (token: string): string[] => {
  try {
    const idTokenClaims = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('utf8'))
    return idTokenClaims.roles || []
  } catch (error) {
    return []
  }
}

const rolesInToken = async (): Promise<string[]> => {
  const token = await accessToken()
  return token ? getRolesFromAccessToken(token) : []
}

const getSitesWithEditRightsOfType = (roles: RoleState, businessUnit: string, rightType: string = RoleRights.EDIT): string[] => {
  const regex = new RegExp(`^${businessUnit}\\.([^.]+)\\.${rightType}$`, 'i')

  return Object.keys(roles)
    .filter(roleKey =>
      roleKey.startsWith(`${businessUnit}.`) && roleKey.toLowerCase().endsWith(`.${rightType.toLowerCase()}`) && roles[roleKey]
    )
    .map(roleKey => {
      const match = regex.exec(roleKey)
      return match ? match[1] : null
    })
    .filter(site => site !== null) as string[]
}

const getSitesFromRoles = (roles: RoleState): ProductionSite[] => {
  const sites = Object.keys(roles)
    .filter(roleKey => roles[roleKey])
    .map(roleKey => roleKey.split('.')[1])
    .filter(site => site !== null) as ProductionSite[]

  return Array.from(new Set(sites))
}

export const RoleProvider: FC<RoleProviderProps> = ({ children }) => {
  const [roles, setRoles] = useState<RoleState | null>(null)
  const [sites, setSites] = useState<ProductionSite[]>([])
  const [selectedSite, setSelectedSitePrivate] = useState<ProductionSite>(window.localStorage.getItem(KEY) as ProductionSite)
  const setSelectedSite = (site: ProductionSite) => {
    window.localStorage.setItem(KEY, site)
    setSelectedSitePrivate(site)
  }

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === KEY && event.newValue) {
        setSelectedSitePrivate(event.newValue as ProductionSite)
      }
    }

    window.addEventListener('storage', handleStorageChange)
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  useEffect(() => {
    const checkRolePresence = async () => {
      try {
        const tokenRoles = await rolesInToken()
        const rolesFetched: RoleState = {}

        Object.values(Roles).forEach((role) => {
          rolesFetched[role] = tokenRoles.includes(role)
        })
        const availableSites = getSitesFromRoles(rolesFetched)

        setRoles(rolesFetched)
        setSites(availableSites)
        if (selectedSite === null) {
          setSelectedSite(availableSites[0])
        }
      } catch (error: any) {
        notifyError(error)
      }
    }
    checkRolePresence()
  }, [])

  const contextValue = useMemo(() => ({
    roles,
    sites,
    selectedSite,
    setSelectedSite,
    getSitesWithEditRightsOfType: (businessUnit: string) => roles ? getSitesWithEditRightsOfType(roles, businessUnit) : []
  }), [roles, sites, selectedSite, setSelectedSite])

  if (roles === null) {
    return null
  }

  return (
    <RoleContext.Provider value={contextValue}>
      {children}
    </RoleContext.Provider>
  )
}

export const useRoleContext = (): RoleContextType => {
  const context = useContext(RoleContext)
  if (!context) {
    throw new Error('useRoleContext must be used within RoleProvider')
  }
  return context
}

export const useRole = (role: Roles): boolean | undefined => {
  const context = useContext(RoleContext)
  if (!context) {
    throw new Error('useRole must be used within RoleProvider')
  }

  const { roles } = context
  return !!roles?.[role]
}

export const useRoles = (): RoleState | null => {
  const context = useContext(RoleContext)
  return context?.roles ?? null
}
